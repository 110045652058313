exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-cambridge-assessment-js": () => import("./../../../src/pages/cambridge-assessment.js" /* webpackChunkName: "component---src-pages-cambridge-assessment-js" */),
  "component---src-pages-certyfikaty-js": () => import("./../../../src/pages/certyfikaty.js" /* webpackChunkName: "component---src-pages-certyfikaty-js" */),
  "component---src-pages-emys-js": () => import("./../../../src/pages/emys.js" /* webpackChunkName: "component---src-pages-emys-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kursy-js": () => import("./../../../src/pages/kursy.js" /* webpackChunkName: "component---src-pages-kursy-js" */),
  "component---src-pages-kursy-zapisy-js": () => import("./../../../src/pages/kursy/zapisy.js" /* webpackChunkName: "component---src-pages-kursy-zapisy-js" */),
  "component---src-pages-nasze-placowki-js": () => import("./../../../src/pages/nasze-placowki.js" /* webpackChunkName: "component---src-pages-nasze-placowki-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-zdjecia-js": () => import("./../../../src/pages/zdjecia.js" /* webpackChunkName: "component---src-pages-zdjecia-js" */)
}

